import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import Div100vh from "react-div-100vh"
import Mailchimp from 'react-mailchimp-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

const NavStyles = styled.div`
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-family: warnock-pro-display, serif;
  position: relative;
  z-index: 90000;
  position: relative;
  ${breakpoint("mobile")`
    font-size: 1.25rem;
  `}
  ${breakpoint("tablet")`
  font-size: 2rem;
  `}
  a {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
      text-decoration-style: wavy;
      /* background: #000; */
      color: red;
    }
  }
  position: absolute;
`

const NavTop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
  z-index: 100;
  mix-blend-mode: hard-light;
  ${breakpoint("mobile")`
  height: 50px;

  `}
  ${breakpoint("tablet")`
  height: 90px;

  `}
`

const NavLeft = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  writing-mode: vertical-lr;
  z-index: 100;
  mix-blend-mode: hard-light;
  ${breakpoint("mobile")`
  width: 50px;

  `}
  ${breakpoint("tablet")`
  width: 90px;

  `}
  a {
    display: block;
    transform: rotate(180deg);
    mix-blend-mode: hard-light;
  }
`

const NavRight = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  writing-mode: vertical-lr;
  z-index: 100;
  mix-blend-mode: hard-light;
  ${breakpoint("mobile")`
  width: 50px;

  `}
  ${breakpoint("tablet")`
  width: 90px;

  `}
`

const NavBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  width: 100%;
  z-index: 100;
  mix-blend-mode: difference;
  ${breakpoint("mobile")`
  height: 50px;

  `}
  ${breakpoint("tablet")`
  height: 90px;

  `}
  a {
    display: block;
    transform: rotate(180deg);
  }
`
const SiteName = styled.div`
  font-family: warnock-pro-display, serif;
  font-size: 0.8rem;
  position: fixed;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  z-index: 999999;

  .mailchimp {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-end;

    input[type="email"] {
      font-family: warnock-pro-display, serif;
      border: none;
      border-bottom: 1px solid black;
      padding: 0px;
      background: none;
      font-weight: bold;
      font-style: italic;
      margin-left: 4px;
    }
    button {
      font-family: warnock-pro-display, serif;
      padding: 0px;
      border: none;
      background: none;
    }
    .msg-alert {
      width: 100%;
    }


  }


  ${breakpoint("mobile")`
  font-size: 0.5rem;

  `}
  ${breakpoint("tablet")`
  font-size: 0.8rem;

  `}
  a {
    color: ${props => (props.hover ? `white` : `black`)};
    /* text-transform: uppercase; */
    text-decoration: none;
  }
  ${props =>
    props.part === 1 &&
    `
       top: 5px;
       left: 5px;
       a {
         display: block;
         /* transform: rotate(-45deg); */
       }
      `}

      ${props =>
        props.part === 2 &&
        `
       top: 5px;
       right: 5px;
       a {
         display: block;
         /* transform: rotate(45deg); */
       }

      `}

      ${props =>
        props.part === 3 &&
        `
       bottom: 5px;
       left: 5px;
       a {
         display: block;

         /* transform: rotate(-135deg); */
       }
      `}

      ${props =>
        props.part === 4 &&
        `
       bottom: 5px;
       right: 5px;
       a {
         display: block;

         /* transform: rotate(135deg); */
       }
      `}
`

const Nav = ({ page }) => {
  return (
    <>
      <SiteName part={1}>
        <a href="/">Los Angeles</a>
      </SiteName>
      <SiteName part={2}>
        <a href="/">Eats Itself</a>
      </SiteName>
      <SiteName part={3}>
        <Mailchimp
          action='https://losangeleseatsitself.us3.list-manage.com/subscribe/post?u=f5036670597f16f1d0c0b26db&id=fc1dfe86c1'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true
            }

          ]}
          className='mailchimp'
          />
      </SiteName>
      <SiteName part={4}>
        <a href="https://instagram.com/losangeleseatsitself/" target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
      </SiteName>
      <NavStyles>
        <NavTop>
          <Link
            to="/meal-index"
            style={{
              color: page === "meal-index" ? "red" : "",
              textDecoration: page === "meal-index" ? "underline" : "",
              textDecorationStyle: page === "meal-index" ? "wavy" : "",
            }}
          >
            Meal Index
          </Link>
        </NavTop>
        <NavLeft active={page === "collaborators"}>
          <Div100vh>
            <Link
              to="/collaborators"
              style={{
                color: page === "collaborators" ? "red" : "",
                textDecoration: page === "collaborators" ? "underline" : "",
                textDecorationStyle: page === "collaborators" ? "wavy" : "",
              }}
            >
              Collaborators
            </Link>
          </Div100vh>
        </NavLeft>

        <NavRight active={page === "about"}>
          <Div100vh>
            <Link
              to="/about"
              style={{
                color: page === "about" ? "red" : "",
                textDecoration: page === "about" ? "underline" : "",
                textDecorationStyle: page === "about" ? "wavy" : "",
              }}
            >
              About
            </Link>{" "}
            •{" "}
            <Link
              to="/press"
              style={{
                color: page === "press" ? "red" : "",
                textDecoration: page === "press" ? "underline" : "",
                textDecorationStyle: page === "press" ? "wavy" : "",
              }}
            >
              press
            </Link>
          </Div100vh>
        </NavRight>

        <NavBottom active={page === "studio"}>
          <Link
            to="/studio"
            style={{
              color: page === "studio" ? "red" : "",
              textDecoration: page === "studio" ? "underline" : "",
              textDecorationStyle: page === "studio" ? "wavy" : "",
            }}
          >
            Studio
          </Link>
        </NavBottom>
      </NavStyles>
    </>
  )
}

export default Nav
